import React, { useEffect } from "react";
import { useUser } from "../context/userContext";
import { formatNumber } from "../utils/functions";
import { useNavigate } from "react-router-dom";
import Animate from "../Components/Animate";
const Splash = () => {
  const {
    plusMiningPower,
    boostedPower,
    balance,
    referrals,
    setTab,
    setHider,
  } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    setHider(true);
  }, []);
  return (
    <div className="max-h-lvh overflow-y-auto">

      <div className="flex flex-col w-full gap-2 text-white items-center">
        <h4 className="font-poppins text-[40px] font-bold">Welcome to the</h4>
        <h4 className="font-poppins text-[60px] font-bold leading-10">BLEGGS</h4>
        <h4 className="font-poppins text-[40px] font-bold">Miner System!</h4>
        <h2 className="font-poppins text-[12px]">
          Engage, Earn, and Grow with the Community
        </h2>
        <div className="customCard-container rounded-full py-2">
          <div
            onClick={() => {
              setTab("Exchange");
              setHider(false);
              navigate("/");
            }}
            className="customCard rounded-full hover:opacity-80 p-2 px-4 font-medium bg-btn"
          >
            <h2 className="font-poppins">Start Earning BLEGGS Today!</h2>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <section className="flex flex-row w-full badge items-baseline justify-around">
          <img
            src="badge 1.png"
            alt="badge"
            className="h-[60px] aspect-square"
          />
          <div className="relative h-[100px] aspect-auto">
            <img
              src="badge 2.png"
              alt="badge"
              className="h-[100px] aspect-auto"
            />
            <div className="absolute flex items-center justify-center top-0 left-0 h-[100px] w-full text-center">
              <h1 className="text-[14px] text-[#FFD798] font-bold">
                {formatNumber(balance)}
              </h1>
            </div>
          </div>
          <img
            src="badge 3.png"
            alt="badge"
            className="h-[60px] aspect-square"
          />
        </section>
        <section className="grid grid-cols-3 space-x-1">
          <div className="flex flex-col items-center">
            <h3 className="text-[16px] text-white py-2">Referrals</h3>
            <div className="customCard-container p-[3px]">
              <div className="customCard text-center cursor-default flex items-center justify-center h-[70px]">
                <h3 className="text-[12px] font-bold">
                  Successful
                  <br />
                  Referrals
                  <br />
                  {referrals}
                </h3>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <h3 className="text-[16px] text-white py-2">Earning</h3>
            <div className="customCard-container p-[3px]">
              <div className="customCard text-center flex cursor-default items-center justify-center h-[70px]">
                <h3 className="text-[12px] font-bold">
                  You Earned
                  <br />
                  {balance.toFixed(2)} BLEGGS
                </h3>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <h3 className="text-[16px] text-white py-2">Hash Power</h3>
            <div className="customCard-container p-[3px]">
              <div className="customCard text-center flex cursor-default items-center justify-center h-[70px]">
                <h3 className="text-[12px] font-bold">
                  {boostedPower > 0 ? boostedPower : plusMiningPower}/MHS
                </h3>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="px-8">
        <h6 className="lilita text-center text-[12px] font-bold bg-btn w-20 rounded-full text-white mt-1">
          Beta V2.0
        </h6>
      </div>

    </div>

  );
};
export default Splash;
